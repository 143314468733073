import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'
import Loading from './Loading'

const PrimaryButton = ({
  text,
  disabled = false,
  loading = false,
  ...restProps
}) => {
  const intl = useIntl()
  return (
    <ContainerButton disabled={disabled}>
      <Button {...restProps}>
        {loading ? <Loading /> : intl.formatMessage({ id: text })}
      </Button>
    </ContainerButton>
  )
}

const ContainerButton = styled.div`
  height: 44px;
  border-radius: 4px;
  background-color: ${props =>
    !props.disabled
      ? props.theme.colors.themePrimary
      : props.theme.colors.neutralLight};
  box-shadow: ${props =>
    `rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${
      !props.disabled
        ? props.theme.colors.themePrimary
        : props.theme.colors.neutralLight
    } 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px`};
`

const Button = styled.button`
  height: 100%;
  width: 100%;
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes[1]}px;
  position: relative;
  z-index: 1;
  flex: 0 1 auto;
  margin: 0;
  text-decoration: none;
  vertical-align: middle;
  word-break: normal;
  background: transparent;
  border: 0;
  outline: 0;
  transition: color 240ms;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default PrimaryButton
