import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { Flex } from 'rebass'
import { hex2rgba } from '../../utils/helpers'

const TextField = ({
  label,
  errorMessage = '',
  multiple = false,
  ...restProps
}) => {
  const refInput = useRef()

  const handleResize = () => {
    refInput.current.style.height = 'auto'
    refInput.current.style.height = refInput.current.scrollHeight + 'px'
  }

  useEffect(() => {
    let listener = refInput.current

    listener && listener.addEventListener('input', handleResize, false)
    return () => {
      listener && listener.removeEventListener('input', handleResize, false)
    }
  }, [])

  return (
    <Flex flexDirection="column" my={3}>
      {label ? <Label>{label}</Label> : null}
      <ContainerInput>
        {multiple ? (
          <Input ref={refInput} as="textarea" {...restProps} />
        ) : (
          <Input {...restProps} />
        )}
      </ContainerInput>
      <ErrorMessage message={errorMessage}>{errorMessage}</ErrorMessage>
    </Flex>
  )
}

const Label = styled.label`
  font-weight: 500;
  font-size: ${props => props.theme.fontSizes[0]}px;
  margin-left: 6px;
`

const Input = styled.input`
  position: relative;
  width: 100%;
  z-index: 1;
  flex: 0 1 auto;
  margin: 0;
  text-decoration: none;
  vertical-align: middle;
  word-break: normal;
  background: transparent;
  border: 0;
  outline: 0;
  transition: all 240ms;
  border-radius: 4px;
  padding: 6px 16px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: 300;
  line-height: 28px;
  resize: none;
`

const ContainerInput = styled.div`
  border-radius: 4px;
  margin-top: 6px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  ${Input}:focus {
    box-shadow: ${props => `rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      ${hex2rgba(props.theme.colors.themePrimary, 0.36)} 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px`};
  }
`

const ErrorMessage = styled.span`
  font-size: ${props => props.theme.fontSizes[0]}px;
  color: ${props => props.theme.colors.red};
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => (props.message ? 1 : 0)};
  margin-top: 2px;
`

export default TextField
