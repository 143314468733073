import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'

import { keyframes } from '@emotion/core'

import { Flex } from 'rebass'

const Loading = ({ vertical = false, label = 'loading' }) => {
  const intl = useIntl()

  return (
    <Flex flexDirection={vertical ? 'column' : 'row'} alignItems="center">
      <Loader />
      <Label>{intl.formatMessage({ id: label })}</Label>
    </Flex>
  )
}

const Label = styled.span`
  font-size: ${props => props.theme.fontSizes[0]}px;
  color: ${props => props.theme.colors.themePrimary};
  margin-left: 5px;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
const Loader = styled.div`
  border: ${props =>
    `4px solid ${props.theme.colors.neutralLighter}`}; /* Light grey */
  border-top: ${props =>
    `4px solid ${props.theme.colors.themePrimary}`}; /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${spin} 2s linear infinite;
`

export default Loading
