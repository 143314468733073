import React from 'react'
import { Flex } from 'rebass'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'
import sendEmail from '../../assets/svg/sendEmail.svg'

const SuccessSend = () => {
  const intl = useIntl()

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <img src={sendEmail} alt="Send email success" />
      <SubText>
        {intl.formatMessage({ id: 'contact_us_success_message' })}
      </SubText>
    </Flex>
  )
}

const SubText = styled.span`
  max-width: 200px;
  font-size: ${props => props.theme.fontSizes[0]}px;
  text-align: center;
  margin-top: 12px;
`
export default SuccessSend
