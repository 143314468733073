import React, { useState } from 'react'
import styled from '@emotion/styled'
import API from '@aws-amplify/api'

import { Box } from 'rebass'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TextField from '../common/TextField'
import PrimaryButton from '../common/PrimaryButton'
import contactTemplate from '../../utils/contactTemplate'
import { useIntl } from 'gatsby-plugin-intl'
import SuccessSend from './SuccessSend'

const FormContact = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const intl = useIntl()

  const {
    values,
    handleSubmit,
    handleChange,
    handleReset,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      phone_number: '',
      affair: '',
      message: '',
    },
    onSubmit: async values => {
      setLoading(true)
      const apiName = 'api616cdc2a'
      const path = '/notifications/email'

      await API.post(apiName, path, {
        body: {
          attributes: {
            email: 'contacto@2dine.io',
            subject: `2Dine | Nuevo prospecto`,
            template: contactTemplate(values),
          },
        },
      })

      setLoading(false)
      setSuccess(true)
      handleReset()
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('This field is required'),
      email: yup
        .string()
        .email('Email is not correct')
        .required('This field is required'),
      message: yup.string().required('This field is required'),
    }),
  })

  if (success) {
    return <SuccessSend />
  }

  return (
    <Form autocomplete="off" onSubmit={handleSubmit}>
      <TextField
        label={intl.formatMessage({ id: 'name' })}
        name="name"
        value={values.name}
        onChange={handleChange}
        errorMessage={touched['name'] && errors['name']}
      />
      <TextField
        label={intl.formatMessage({ id: 'company' })}
        name="company"
        value={values.company}
        onChange={handleChange}
      />
      <TextField
        label={intl.formatMessage({ id: 'email' })}
        name="email"
        value={values.email}
        onChange={handleChange}
        errorMessage={touched['email'] && errors['email']}
      />
      <TextField
        label={intl.formatMessage({ id: 'phone_number' })}
        name="phone_number"
        value={values.phone_number}
        onChange={handleChange}
      />
      <TextField
        label={intl.formatMessage({ id: 'affair' })}
        name="affair"
        value={values.affair}
        onChange={handleChange}
      />
      <TextField
        label={intl.formatMessage({ id: 'message' })}
        name="message"
        multiple
        autoAdjustHeight
        value={values.message}
        errorMessage={touched['message'] && errors['message']}
        onChange={handleChange}
      />
      <Box w={1} style={{ marginTop: '42px' }}>
        <PrimaryButton
          disabled={loading}
          loading={loading}
          text="submit"
          type="submit"
        />
      </Box>
    </Form>
  )
}

const Form = styled.form`
  width: 320px;
  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    width: 280px;
  }
`

export default FormContact
