import React from 'react'
import { Box, Flex } from 'rebass'
import { Link } from 'gatsby-plugin-intl'

import CloseButton from './CloseButton'

const ContentModal = ({ children, closeTo = '/' }) => {
  return (
    <Box width={1}>
      <Flex justifyContent="flex-end">
        <CloseButton as={Link} to={closeTo} />
      </Flex>
      <Box
        style={{
          padding: '20px 36px',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContentModal
