export default ({ name, company, email, phone_number, affair, message }) => {
  return `
    <!DOCTYPE html>
<html>
  <head>
    <meta name="viewport" content="width=device-width" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <link
      href="https://fonts.googleapis.com/css?family=Montserrat:100,300,500|Quicksand"
      rel="stylesheet"
    />
    <title>Simple Transactional Email</title>
    <style>
      /* -------------------------------------
        INLINED WITH htmlemail.io/inline
    ------------------------------------- */
      /* -------------------------------------
        RESPONSIVE AND MOBILE FRIENDLY STYLES
    ------------------------------------- */

      @media only screen and (max-width: 620px) {
        table[class="body"] h1 {
          font-size: 28px !important;
          margin-bottom: 10px !important;
        }
        table[class="body"] p,
        table[class="body"] ul,
        table[class="body"] ol,
        table[class="body"] td,
        table[class="body"] span,
        table[class="body"] a {
          font-size: 16px !important;
        }
        table[class="body"] .wrapper,
        table[class="body"] .article {
          padding: 10px !important;
        }
        table[class="body"] .content {
          padding: 0 !important;
        }
        table[class="body"] .container {
          padding: 0 !important;
          width: 100% !important;
        }
        table[class="body"] .main {
          border-left-width: 0 !important;
          border-radius: 0 !important;
          border-right-width: 0 !important;
        }
        table[class="body"] .btn table {
          width: 100% !important;
        }
        table[class="body"] .btn a {
          width: 100% !important;
        }
        table[class="body"] .img-responsive {
          height: auto !important;
          max-width: 100% !important;
          width: auto !important;
        }
      }
      table[class="body"] .link_menu {
        padding: 0 8px;
        color: #e0e0e0;
        text-decoration: none;
        font-weight: 100;
      }

      table[class="body"] .link_menu:hover {
        color: #ffffff;
      }

      @media screen and (max-width: 500px) {
        table[class="body"] h1 {
          font-size: 28px !important;
          margin-bottom: 10px !important;
        }
        .stack-column-center {
          display: block !important;
          width: 100% !important;
          max-width: 100% !important;
          direction: ltr !important;
        }
      }
      /* -------------------------------------
        PRESERVE THESE STYLES IN THE HEAD
    ------------------------------------- */

      @media all {
        .ExternalClass {
          width: 100%;
        }
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
          line-height: 100%;
        }
        .apple-link a {
          color: inherit !important;
          font-family: inherit !important;
          font-size: inherit !important;
          font-weight: inherit !important;
          line-height: inherit !important;
          text-decoration: none !important;
        }
        .btn-primary table td:hover {
          background-color: transparent !important;
        }
        .btn-primary a:hover {
          background-color: #e09600 !important;
          border-color: #e09600 !important;
        }
      }
    </style>
  </head>

  <body
    class=""
    style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; max-width: "
  >
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      class="body"
      style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; background-color: #f6f6f6;"
    >
      <tr>
        <td
          style="font-family: sans-serif; font-size: 14px; vertical-align: top;"
        >
          &nbsp;
        </td>
        <td
          class="container"
          style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; margin: 0 auto; max-width: 800px; width: 800px;"
        >
          <div
            class="content"
            style="min-height: 120px; position: relative; z-index: 2; margin-bottom: 12px; box-sizing: border-box; display: block; padding: 8px 0; background-color:#ffaa00;"
          >
            <table
              align="top"
              border="0"
              cellpadding="0"
              cellspacing="0"
              style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%; margin-bottom: 25px"
            >
              <tr>
                <td colspan="5" style="text-align: -webkit-left;">
                  <h2 style="padding-left: 16px; color: #fff;">
                    2Dine | Nuevo prospecto
                  </h2>
                </td>
              </tr>
            </table>
          </div>
          <div
            class="content"
            style="box-sizing: border-box; display: block; position: relative;"
          >
            <!-- START CENTERED WHITE CONTAINER -->

            <table
              class="main"
              style="position: relative; margin: auto; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 75%;"
            >
              <!-- START MAIN CONTENT AREA -->
              <tr>
                <td
                  class="wrapper"
                  style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;"
                  >
                    <tr>
                      <td
                        colspan="2"
                        style="font-family: sans-serif; font-size: 14px; vertical-align: top;"
                      >
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Nombre:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${name}
												</p>
												<p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Compañia:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${company || '-'}
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Email:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${email}
												</p>
												<p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Teléfono:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${phone_number || '-'}
												</p>
												<p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Asunto:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${affair || '-'}
												</p>
												<p
                          style="font-family: sans-serif; font-size: 18px; font-weight: 300; margin: 0; Margin-bottom: 5px;"
                        >
                          Mensaje:
                        </p>
                        <p
                          style="font-family: sans-serif; font-size: 18px; font-weight: bold; margin: 0; margin-bottom: 15px"
                        >
                          ${message}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="2"
                        valign="top"
                        width="100%"
                        style="padding: 10px;"
                      >
                        <table
                          role="presentation"
                          cellspacing="0"
                          cellpadding="0"
                          border="0"
                          width="100%"
                        >
                          <tr>
                            <td class="stack-column-center">
                              <table
                                role="presentation"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tr style="text-align: center">
                                  <td style="padding: 0 10px;">
                                    <img
                                      alt="logo"
                                      resizemode="contain"
                                      style="width: 200px"
                                      src="https://s3.amazonaws.com/as-public-images/image_contactus.png"
                                    />
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td class="stack-column-center">
                              <table
                                role="presentation"
                                cellspacing="0"
                                cellpadding="0"
                                border="0"
                              >
                                <tr style="text-align: center">
                                  <td style="padding: 0 10px;">
                                    <p
                                      align="left"
                                      style="color: #333333; font-size: 20px; font-weight: bold; text-align: left; margin: 0"
                                    >
                                      ¿Necesitas Ayuda?
                                    </p>
                                    <p
                                      align="left"
                                      style="color: #5E5E5E; margin-top: 2px;"
                                    >
                                      Escríbenos a
                                      <a
                                        href="mailto:hola@2shopapp.com"
                                        style="color: #00A2FF"
                                        >hola@2shopapp.com</a
                                      >
                                      o comunícate al teléfono 55 3734 1530
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <div
            class="footer"
            style="margin-top: 25px; box-sizing: border-box; display: block; background-color: #333333"
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;"
            >
              <tr>
                <td style="width: 20%" />
                <td
                  class="content-block"
                  style=" border-top: 2px solid #5E5E5E; font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: #999999; text-align: center;"
                >
                  <span
                    class="apple-link"
                    style="color: white; font-size: 12px; text-align: center;"
                    >Copyright © 2020 2dine, Inc. All rights reserved.</span
                  >
                </td>
                <td style="width: 20%" />
              </tr>

              <tr>
                <td />
                <td
                  class="content-block"
                  style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: #999999; text-align: center;"
                >
                  <p style="margin: 0; font-weight: bold; color: white">
                    Contáctanos en:
                  </p>
                  <a
                    href="mailto:contacto@2dine.io"
                    style="color: white; text-decoration-line: underline; text-decoration-style: solid; text-decoration-color: white"
                    >contacto@2dine.io</a
                  >
                </td>
                <td />
              </tr>
              <tr>
                <td />
                <td
                  class="content-block powered-by"
                  style="color: white; font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; font-size: 12px; color: #999999; text-align: center;"
                >
                  <span style="color: white">Powered by </span>
                  <a
                    href="https://www.plukketech.com/"
                    style="color: white; font-size: 12px; text-align: center; text-decoration: none;"
                    >Plukke</a
                  >.
                </td>
                <td />
              </tr>
            </table>
          </div>
        </td>
        <td
          style="font-family: Montserrat; font-size: 14px; vertical-align: top;"
        >
          &nbsp;
        </td>
      </tr>
    </table>
  </body>
</html>

    `
}
