import React from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'
import { Flex } from 'rebass'

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import ContentModal from '../components/common/ContentModal'
import FormContact from '../components/contact/FormContact'
import Layout from '../components/Layout'

const ContactUs = props => {
  const {
    pageContext: { intl },
  } = props
  const { language } = intl
  const intlLanguage = useIntl()
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <ContentModal closeTo={closeTo.replace(`/${language}`, '')}>
              <Flex flexDirection="column" alignItems="center">
                <Title>
                  {intlLanguage.formatMessage({ id: 'contact_us_title' })}
                </Title>
                <Description>
                  {intlLanguage.formatMessage({ id: 'contact_us_description' })}
                </Description>
                <FormContact />
              </Flex>
            </ContentModal>
          ) : (
            <Layout {...props}>
              <Flex
                px={3}
                justifyContent="center"
                style={{ margin: '300px 0 20px 0' }}
              >
                <ContainerForm flexDirection="column" alignItems="center">
                  <Title>
                    {intlLanguage.formatMessage({ id: 'contact_us_title' })}
                  </Title>
                  <Description>
                    {intlLanguage.formatMessage({
                      id: 'contact_us_description',
                    })}
                  </Description>
                  <FormContact />
                </ContainerForm>
              </Flex>
            </Layout>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

const ContainerForm = styled(Flex)`
  padding: 32px 44px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  @media only screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: 32px 32px;
  }
`

const Title = styled.h2`
  margin: 0;
  text-align: center;
`

const Description = styled.p`
  text-align: center;
  margin: 0 0 12px 0;
  font-size: ${props => props.theme.fontSizes[1]}px;
  max-width: 250px;
`

export default ContactUs
